import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/VerisureveiligHome.vue";
import Bedankt from "../views/VerisureveiligBedankt.vue";
import VideodeurbelveiligHome from "../views/VideodeurbelveiligHome.vue";
import VideodeurbelveiligBedankt from "../views/VideodeurbelveiligBedankt.vue";
import InbraakonderzoekHome from "../views/InbraakonderzoekHome.vue";
import InbraakonderzoekBedankt from "../views/InbraakonderzoekBedankt.vue";
import videodeurbelinbraakonderzoekzoekHome from "../views/videodeurbelinbraakonderzoekHome.vue";
import videodeurbelinbraakonderzoekzoekBedankt from "../views/videodeurbelinbraakonderzoekBedankt.vue";

Vue.use(VueRouter);

const routes = [
  // verisureveilig
  // {
  //   path: "/",
  //   name: "Home",
  //   component: Home,
  //   redirect: "/verisureveilig",
  // },
  // {
  //   path: "/verisureveilig",
  //   name: "Home",
  //   component: Home,
  // },
  // {
  //   path: "/bedankt",
  //   name: "Bedankt",
  //   component: Bedankt,
  //   redirect: "/verisureveilig/bedankt",
  // },
  // {
  //   path: "/verisureveilig/bedankt",
  //   name: "Bedankt",
  //   component: Bedankt,
  // },
  //videodeurbelveilig
  // {
  //   path: "/videodeurbelveilig",
  //   name: "VideodeurbelveiligHome",
  //   component: VideodeurbelveiligHome,
  // },
  // {
  //   path: "/videodeurbelveilig/bedankt",
  //   name: "VideodeurbelveiligBedankt",
  //   component: VideodeurbelveiligBedankt,
  // },
  // // Inbraakonderzoek
  // {
  //   path: "/inbraakonderzoek",
  //   name: "InbraakonderzoekHome",
  //   component: InbraakonderzoekHome,
  // },
  // {
  //   path: "/inbraakonderzoek/bedankt",
  //   name: "InbraakonderzoekBedankt",
  //   component: InbraakonderzoekBedankt,
  // },
  // // videodeurbelinbraakonderzoek
  // {
  //   path: "/videodeurbelinbraakonderzoek",
  //   name: "videodeurbelinbraakonderzoekzoekHome",
  //   component: videodeurbelinbraakonderzoekzoekHome,
  // },
  // {
  //   path: "/videodeurbelinbraakonderzoek/bedankt",
  //   name: "videodeurbelinbraakonderzoekzoekBedankt",
  //   component: videodeurbelinbraakonderzoekzoekBedankt,
  // },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
