import Vue from "vue";
import Vuex from "vuex";
import Verisureveilig from "./modules/Verisureveilig.js";
import Videodeurbelveilig from "./modules/Videodeurbelveilig.js";
import inbraakonderzoek from "./modules/inbraakonderzoek.js";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    Verisureveilig,
    Videodeurbelveilig,
    inbraakonderzoek,
  },
});
