import Verisureveilig from "./Verisureveilig";
import Videodeurbelveilig from "./Videodeurbelveilig";
import Inbraakonderzoek from "./Inbraakonderzoek";
const LEADS = "leads";
import qs from "qs";

export default {
  postLead(payload) {
    return Verisureveilig.post(`${LEADS}`, qs.stringify(payload));
  },
  videodeurbelveiligLead(payload) {
    return Videodeurbelveilig.post(`${LEADS}`, qs.stringify(payload));
  },
  inbraakonderzoekLead(payload) {
    return Inbraakonderzoek.post(`${LEADS}`, qs.stringify(payload));
  },
};
