<template>
  <div>
    <div class="triangle text-center">
      <h1 class="py-3">Profiteer nu van de ZOMER-AANBIEDING!</h1>
    </div>
    <b-container class="profiteer">
      <div class="row">
        <b-col lg="8" sm="12">
          <div class="row">
            <div class="col-lg-4 col-sm-1 offset-sm-0 offset-1 zIndex2 offer">
              <div class="profiteer__startBg p-4">
                <h3 class="text-center text-white pt-5">
                  <span class="pt-3">
                    <b>
                      Alleen
                      <br />
                      nu</b
                    ></span
                  >
                </h3>
              </div>
              <div class="profiteer__circle">
                <h2 class="text-center mt-4 mt-md-5 pt-3 text-white">
                  NU TOT <br />
                  <span class="text-dark">40%</span><br />
                  KORTING
                </h2>
              </div>
            </div>
            <div class="col-lg-8 col-md-8 col-sm-12 zIndex1 offerImg">
              <img src="../../assets/offer.png" width="100%" />
            </div>
          </div>
        </b-col>
        <!--  -->
        <b-col lg="4" md="12" sm="12" class="profiteer__list">
          <div class="container">
            <ul class="mt-5 pt-5 pt-md-0">
              <li class="mt-5 mt-md-0">
                <span><i class="bi bi-check2-circle text-danger"></i></span>
                Meldkamer 24/7
              </li>
              <li>
                <span><i class="bi bi-check2-circle text-danger"></i></span>
                Star Key
              </li>
              <li>
                <span><i class="bi bi-check2-circle text-danger"></i></span>
                Overvalknop
              </li>
              <li>
                <span><i class="bi bi-check2-circle text-danger"></i></span>
                Mobiele app
              </li>
              <li>
                <span><i class="bi bi-check2-circle text-danger"></i></span>
                Shocksensor
              </li>
              <li>
                <span><i class="bi bi-check2-circle text-danger"></i></span>
                Bewakingscamera
              </li>
              <li>
                <span><i class="bi bi-check2-circle text-danger"></i></span>
                Fotodetector
              </li>
              <li>
                <span><i class="bi bi-check2-circle text-danger"></i></span>
                Rookdetector
              </li>
            </ul>
          </div>
        </b-col>
      </div>
    </b-container>
    <div class="triangle__bottom text-center"></div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.triangle {
  background: url(../../assets/redTriangle.png);
  height: 100px;
  background-repeat: no-repeat;
  background-size: 200% 105%;
  background-position-x: center;
  margin-top: -23px;
  & h1 {
    color: white;
    font-weight: bold;
    @media only screen and (max-width: 991px) {
      font-size: 30px;
    }
    @media only screen and (max-width: 766px) {
      font-size: 19px;
    }
  }
}
.profiteer {
  &__startBg {
    background: url(../../assets/star-bg.png);
    height: 200px;
    width: 200px;
    background-repeat: no-repeat;
    background-position: center;
  }
  &__circle {
    width: 250px;
    height: 250px;

    line-height: 1;
    border: 5px solid #fff;
    border-radius: 50%;
    background-color: #4dd1ed;
    margin-left: 50px;
    margin-top: -35px;
    & h2 {
      font-size: 31px;
      font-weight: 700;
    }
    @media only screen and (max-width: 766px) {
      width: 200px !important;
      height: 200px !important;
    }
  }
  &__list ul {
    list-style: none;
  }
  &__list li {
    font-size: 25px;
    font-weight: 600;
  }
  &__list li span {
    font-size: 30px;
    margin-right: 10px;
  }
}
.triangle__bottom {
  background: url(../../assets/triangle.png);
  height: 100px;
  background-size: 200% 105%;
  background-position-x: center;
  margin-top: -55px;
  background-position-y: -16px;
}
.offer {
  @media only screen and (max-width: 766px) {
    position: absolute !important;
    width: 50% !important;
    left: -60px;
    top: -30px;
  }
}
.offerImg {
  @media only screen and (max-width: 766px) {
    width: 65%;
    margin-left: 164px;
  }
}

.zIndex2 {
  z-index: 2;
}
.zIndex1 {
  z-index: 1;
}
</style>
