<template>
  <div class="home">
    <Bedankt />
    <HetSection />
    <Footer />
  </div>
</template>

<script>
import Bedankt from "../components/inbraakonderzoek/Bedankt.vue";
import HetSection from "../components/inbraakonderzoek/HetSection.vue";
import Footer from "../components/inbraakonderzoek/Footer.vue";
// @ is an alias to /src

export default {
  name: "InbraakonderzoekBedankt",
  components: {
    Bedankt,
    HetSection,
    Footer,
  },
};
</script>
