<template>
  <div class="controleer pt-5" id="form">
    <b-container>
      <div class="container controleer__heading text-center">
        <h1 class="text-white px-5 mb-5">
          Controleer of uw woning in aanmerking komt voor <br />
          <span>het nieuwe revolutionaire alarmsysteem van Verisure</span>
        </h1>
      </div>

      <!-- bedankt -->
      <b-container class="bedankt text-center py-5 px-0 px-md-4">
        <div class="container fade-in">
          <h3>
            <span>{{ videodeurbelveiligname }}</span
            >, bedankt voor uw deelname en aanvraag.
          </h3>
          <p>
            Een van onze beveiligingsexperts zal in de komende dagen telefonisch
            contact met u opnemen om het inbraak risico en uw wensen door te
            nemen.
          </p>
          <h4>Geef inbrekers geen kans met Verisure.</h4>
          <img src="../../assets/logo.png" alt="" />

          <img
            referrerpolicy="no-referrer-when-downgrade"
            :src="`https://republish.nationalebelangen.nl/m/5407/08bfe2827b37/?event=6319&unique_conversion_id=${videodeurbelveiligprofId}`"
            style="width: 1px; height: 1px; border: 0px"
          />
        </div>
      </b-container>

      <b-row class="py-5">
        <div class="col-lg-6 col-sm-12">
          <img src="../../assets/living.png" width="100%" />
          <img
            src="../../assets/protected.png"
            class="controleer__protectedImg ml-4"
            width="37%"
          />
        </div>

        <div class="col-lg-6 col-sm-12">
          <div class="container px-3 my-5 my-md-0">
            <h4 class="text-white">
              Het <b> Verisure alarmsysteem</b> biedt de beste bescherming voor
              uw woning en bedrijf. 24 uur per dag/365 dagen per jaar.
            </h4>
            <div class="my-4 controleer__list text-white">
              <div class="row">
                <div class="col-1">
                  <span class="text-white"
                    ><i class="bi bi-check-circle"></i
                  ></span>
                </div>
                <div class="col-10">
                  <h5>Inbraakbeveiliging</h5>
                </div>
              </div>
              <p class="ml-5">
                Inclusief Politie Live View / SOS alarm Snel geverifieerd alarm
                dankzij fotodetectie
              </p>

              <div class="row">
                <div class="col-1">
                  <span class="text-white"
                    ><i class="bi bi-check-circle"></i
                  ></span>
                </div>
                <div class="col-10">
                  <h5>ZeroVision</h5>
                </div>
              </div>
              <p class="ml-5">Rookgordijn activatie binnen 60 seconden</p>
              <div class="row">
                <div class="col-1">
                  <span class="text-white"
                    ><i class="bi bi-check-circle"></i
                  ></span>
                </div>
                <div class="col-10">
                  <h5>Inclusief alarmopvolging door surveillant en politie</h5>
                </div>
              </div>
              <p class="ml-5">Eigen alarmcentrale 24/7</p>

              <div class="row">
                <div class="col-1">
                  <span class="text-white"
                    ><i class="bi bi-check-circle"></i
                  ></span>
                </div>
                <div class="col-10">
                  <h5>Rookmelders die onderling communiceren</h5>
                </div>
              </div>
              <p class="ml-5">
                App waarmee elektrische apparaten bediend kunnen worden
              </p>
            </div>
          </div>
        </div>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Bedankt",
  computed: mapGetters(["videodeurbelveiligprofId", "videodeurbelveiligname"]),
};
</script>

<style lang="scss">
.bedankt {
  background-color: #eaeaea;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1d252d;
  & h3 {
    color: red !important;
    font-size: 35px;
    line-height: 1.2;
    font-weight: 700 !important;
    @media only screen and (max-width: 766px) {
      font-size: 27px;
    }
  }
  & p {
    font-size: 1.5rem;
    line-height: 1.6;
    font-weight: lighter !important;
    color: #4b4b4b !important;
  }
  & h4 {
    font-size: 1.5rem;
    line-height: 1.7;
    font-weight: 700 !important;
    color: #4b4b4b !important;
  }
}
</style>
